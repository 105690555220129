// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/client/signup-bg.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".signup-page[data-v-d398d558]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.signup-page .language-switcher-large[data-v-d398d558]{display:flex!important}.tw-ms-auto[data-v-d398d558]{margin-inline-start:auto}", ""]);
// Exports
module.exports = exports;
