<template>
  <div
    class="tw-relative tw-bg-center tw-bg-cover tw-min-h-screen signup-page"
  >


    <div class="tw-flex tw-justify-center tw-w-full">
      <header class="bg-transparent tw-relative tw-inset-x-0 tw-top-0 md:tw-mt-10 tw-z-10 tw-container">
        <div class="tw-px-4 tw-mx-auto sm:tw-px-6 lg:tw-px-8">
          <div
            class="tw-flex tw-items-center tw-justify-between tw-h-16 lg:tw-h-20"
          >
         
            <div class="tw-flex-shrink-0">
              <template v-if="!$store.getters['auth/isLoggedIn']">
                <router-link :to="homeRoute[this.$route.name]" title="" class="tw-flex">
                  <img loading="lazy"
                    src="@/assets/images/logo/Asset 1.svg"
                    alt=""
                    class="tw-w-auto tw-h-[52px]"
                  />
                </router-link>
              </template>
              <div v-else class="tw-flex" @click.prevent>
                <img loading="lazy"
                  src="@/assets/images/logo/Asset 1.svg"
                  alt=""
                  class="tw-w-auto tw-h-[52px]"
                />
              </div>
            </div>

            <div
              class="lg:tw-flex lg:tw-items-center lg:tw-justify-end lg:tw-space-x-6 tw-ms-auto"
            >
              <div class="btn-group" style="order: 2">
                <LanguageSwitcher black class="language-switcher-large tw-text-[#1b1b1b] !flex" />
              </div>
           

              

             
            </div>
          </div>
        </div>
      </header>
    </div>


    <router-view></router-view>
  </div>
</template>

<script>
import LanguageSwitcher from "@/components/shared/languageSwitcher/languageSwitcher.vue";

export default {
  components: { LanguageSwitcher },
  data() {
    return {
      homeRoute: {
        'talent-signup': '/home/talent',
        'client-signup': '/home/client',
        'Login': localStorage.getItem('activeUserType') ? localStorage.getItem('activeUserType') === 'client' ? '/home/client' : '/home/talent' : '/home/client' ,
      }
    };
  },
  methods: {
  
  },
};
</script>

<style lang="scss" scoped>
.signup-page {

      /* background-image: url("../assets/images/bg-signin-2.png");*/

       background-image: url("../assets/images/client/signup-bg.svg");

      .language-switcher-large {
        display: flex !important;
      }
}

.tw-ms-auto {
    margin-inline-start: auto;
}
</style>